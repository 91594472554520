/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable func-names */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-shadow */
/* eslint-disable no-plusplus */
/* eslint-disable import/imports-first */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { useHistory, Link, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import './styles.css';
import TableChartIcon from '@material-ui/icons/TableChart';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import CssBaseline from '@material-ui/core/CssBaseline';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import {
  AppBar,
  Toolbar,
  List,
  Divider,
  IconButton,
  Drawer,
  Container,
  Grid
} from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ListAltIcon from '@material-ui/icons/ListAlt';
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import { FaDatabase, FaDoorClosed } from 'react-icons/fa';
import dsm_logo from 'assets/DSM_MasterLogo.png';
import {
  NormalizerFilter,
  ParamsFilter,
  AuxilioRight,
  TablesExplores,
  Snippet
} from 'componentes';
import { setOpenMenuStudio } from 'store/modules/studio/actions';
import ToolTip from '../Toolpit';
import { MenuList, Logout } from './MenuList';
import { Theme } from './styles';

const NavMenu = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { username } = useSelector(state => state.auth);
  const { studio } = useSelector(state => state);
  const [openMenu, setOpenMenu] = useState(false);
  const [openFilter, setOpenFilter] = useState({
    treeView: false,
    aux: false,
    tables: false,
    filter: history.location.pathname === '/normalizer'
  });

  const drawerWidth = 240;

  let activeFilter = false;
  let activeTreeView = false;
  let activeAux = false;
  let activeTables = false;
  let activeSnippet = false;

  if (history.location.pathname === '/report') {
    activeFilter = false;
    activeTreeView = true;
    activeAux = true;
    activeTables = true;
    activeSnippet = true;
  }

  if (history.location.pathname === '/normalizer') {
    activeFilter = true;
  }

  const UseStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      padding: 0,
      margin: 'auto',
      '& > *': {
        marginBottom: theme.spacing(2)
      },
      '& .MuiBadge-root': {
        marginRight: theme.spacing(4)
      }
    },
    toolbar: {
      paddingRight: 24 // keep right padding when drawer closed
    },
    toolbarIcon: {
      background: '#009FE3',
      display: 'flex',
      height: '4px',
      alignItems: 'space-between',
      justifyContent: 'space-between',
      padding: '12px 12px 12px 12px',
      ...theme.mixins.toolbar
    },
    tolbarFilter: {
      width: '40px',
      background: '#e8e8e8;',
      overflow: 'hidden',
      display: 'absolute',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      boxShadow: '1px -1px 9px #808080'
    },
    profileIcon: {
      position: 'absolute',
      right: '0',
      marginTop: '-20px'
    },

    logo: {
      width: '80px',
      height: '40px',
      background: '#009FE3'
    },
    closeLogo: {
      position: 'absolute',
      width: '40px',
      height: '40px'
    },

    appBar: {
      zIndex: theme.zIndex.drawer + 1,

      height: '64px',
      boxShadow: '1px -1px 9px #808080',
      background: '#F0F0F0',
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    appBarShift: {
      marginLeft: drawerWidth,

      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    menuButton: {
      marginRight: 36
    },
    menuButtonHidden: {
      display: 'none'
    },
    title: {
      flexGrow: 1
    },
    drawerPaper: {
      position: 'relative',
      background: '#009FE3',
      whiteSpace: 'nowrap',

      width: drawerWidth,
      height: '100%',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      }),
      boxShadow: '1px -1px 9px #808080'
    },
    drawerFilter: {
      overflowX: 'hidden',
      position: 'relative',
      background: '#F0F0F0',
      whiteSpace: 'nowrap',
      width: 400,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      width: theme.spacing(8),
      height: '100%',
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9)
      },
      boxShadow: '1px -1px 9px #808080'
    },
    drawerFilterClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),

      width: 0
    },
    drawerResultClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),

      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(6)
      }
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto'
    },
    closeIcon: {
      position: 'absolute',
      right: '0',
      padding: '5px'
    },
    container: {
      // paddingTop: theme.spacing(4),
      // paddingBottom: theme.spacing(4)
      margin: 0,
      padding: 0,
      outline: 0,
      boxsizing: 'border-box',
      overflow: 'hidden',
      maxWidth: '2000px'
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column'
    },
    fixedHeight: {
      height: 240
    },
    menu: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'space-between',
      justifyContent: 'space-between'
    },
    filterTitle: {
      fontSize: '16px',
      color: '#808080',
      fontWeight: 'bold'
    },
    selectInput: {
      width: '100%',
      height: '36px',
      textAlignLast: 'center',
      fontSize: '12px',
      padding: '5px',
      border: `1px solid #d3d3d3 `,
      borderRadius: '5px',
      outline: 0,
      transition: 'all ease .4s',
      marginBottom: '20px',
      backgroundColor: '#fff',

      '& option': {
        cursor: 'pointer',

        textAlignLast: 'center',
        height: '40px',
        '&:hover': {
          boxShadow: `0 10px 100px #009FE3 inset`
        }
      },
      '&:focus': {
        border: `1px solid yellow`,
        color: '#000'
      }
    },
    menuBurger: {
      marginLeft: -30,
      width: 78,
      height: '100%',
      paddingTop: '5px',
      background: '#009FE3;'
    },
    menuBurgerExpand: {
      display: 'flex',
      marginLeft: 0,
      marginTop: -10,
      justifyContent: 'space-between',
      width: '100%',
      height: '100%',
      background: '#009FE3;'
    },
    logoDsm: {
      position: 'absolute',
      height: '40px',
      width: '125px',
      left: '45%'
    },
    form: {
      display: 'flex',
      flexDirection: 'column',

      '& input': {
        width: '100%',
        height: '36px',
        fontSize: '14px',
        border: `1px solid #F0F0F0`,
        borderRadius: '5px',
        outline: 0,
        transition: 'all ease .4s',
        marginBottom: '15px',
        backgroundColor: '#fff',

        '&:focus': {
          border: `1px solid #FFCD00`,
          color: '#000'
        }
      },
      '& button': {
        cursor: 'pointer',
        height: '36px',
        width: '60px',
        margin: theme.spacing(1, 0, 1),
        backgroundColor: '#FFCD00',
        color: '#F0F0F0',
        borderRadius: '5px',
        border: `1px solid #F0F0F0`,
        outline: 0,
        '&:hover': {
          backgroundColor: '#FFCD00',
          opacity: '10'
        }
      }
    }
  }));

  const location = useLocation();

  useEffect(() => {
    dispatch(setOpenMenuStudio(false));
    setOpenFilter({
      treeView: false,
      filter: false,
      aux: false,
      tables: false,
      snippet: false
    });
  }, [location.pathname]);

  const classes = UseStyles();

  return (
    <Theme>
      <div className={classes.root}>
        <CssBaseline />

        <AppBar
          id="app-bar"
          position="absolute"
          className={clsx(classes.appBar, openMenu && classes.appBarShift)}
        >
          <Toolbar className={classes.toolbar}>
            {!openMenu && (
              <ToolTip title="expand" placement="right">
                <div tabIndex={-1} className={classes.menuBurger}>
                  <IconButton
                    tabIndex={-1}
                    onClick={() => setOpenMenu(!openMenu)}
                    style={{
                      cursor: 'pointer',
                      marginLeft: 10
                    }}
                  >
                    <MenuIcon
                      tabIndex={-1}
                      style={{
                        color: '#fff',
                        height: 35,
                        width: 35
                      }}
                    />
                  </IconButton>
                </div>
              </ToolTip>
            )}
            <img className={classes.logoDsm} src={dsm_logo} />
            <ToolTip title={username} placement="right-start">
              <IconButton color="#009FE3" className={classes.profileIcon}>
                <AccountCircleIcon
                  style={{ color: '#009FE3' }}
                  fontSize="large"
                />
              </IconButton>
            </ToolTip>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(
              classes.drawerPaper,
              !openMenu && classes.drawerPaperClose
            )
          }}
          open={openMenu}
        >
          <div className={classes.toolbarIcon}>
            <MenuIcon
              onClick={() => setOpenMenu(!openMenu)}
              tabIndex={-1}
              style={{
                cursor: 'pointer',
                color: '#fff',
                height: 35,
                width: 35
              }}
            />
            <ToolTip title="minimize" placement="right">
              <ChevronLeftIcon
                onClick={() => setOpenMenu(!openMenu)}
                fontSize="large"
                style={{ color: '#fff', cursor: 'pointer' }}
              />
            </ToolTip>
          </div>
          <div className={classes.menu}>
            <MenuList open={openMenu} />

            <Divider />
            <List />
            <div style={{ width: '100%', position: 'absolute', bottom: '0px' }}>
              <Logout />
            </div>
          </div>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container className={classes.container}>{children}</Container>
        </main>
        <Toolbar className={classes.tolbarFilter}>
          <Grid container spacing={3}>
            {activeTreeView && (
              <Grid item xs={12} style={{ marginTop: 65 }}>
                <IconButton
                  style={{ marginLeft: -25 }}
                  onClick={() => {
                    setOpenFilter({
                      treeView: !openFilter.treeView,
                      filter: false,
                      aux: false,
                      tables: false,
                      snippet: false
                    });
                    dispatch(setOpenMenuStudio(!openFilter.treeView));
                  }}
                >
                  <ListAltIcon />
                </IconButton>
              </Grid>
            )}

            {activeFilter && (
              <Grid item xs={12} style={{ marginTop: 65 }}>
                <IconButton
                  style={{ marginLeft: -25 }}
                  onClick={() => {
                    setOpenFilter({
                      treeView: false,
                      filter: !openFilter.filter,
                      aux: false,
                      tables: false,
                      snippet: false
                    });
                    dispatch(setOpenMenuStudio(FaDoorClosed));
                  }}
                >
                  <FilterListIcon />
                </IconButton>
              </Grid>
            )}

            {activeAux && (
              <Grid item xs={12} style={{ marginTop: -20 }}>
                <IconButton
                  style={{ marginLeft: -25 }}
                  onClick={() => {
                    setOpenFilter({
                      treeView: false,
                      filter: false,
                      aux: !openFilter.aux,
                      tables: false,
                      snippet: false
                    });
                    dispatch(setOpenMenuStudio(!openFilter.aux));
                  }}
                >
                  <ColorLensIcon />
                </IconButton>
              </Grid>
            )}

            {activeTables && (
              <Grid item xs={12} style={{ marginTop: -20 }}>
                <IconButton
                  style={{ marginLeft: -25 }}
                  onClick={() => {
                    setOpenFilter({
                      treeView: false,
                      filter: false,
                      aux: false,
                      tables: !openFilter.tables,
                      snippet: false
                    });
                    dispatch(setOpenMenuStudio(!openFilter.tables));
                  }}
                >
                  <TableChartIcon />
                </IconButton>
              </Grid>
            )}

            {activeSnippet && (
              <Grid item xs={12} style={{ marginTop: -20 }}>
                <IconButton
                  style={{ marginLeft: -25 }}
                  onClick={() => {
                    setOpenFilter({
                      treeView: false,
                      filter: false,
                      aux: false,
                      tables: false,
                      snippet: !openFilter.snippet
                    });
                    dispatch(setOpenMenuStudio(!openFilter.snippet));
                  }}
                >
                  <DynamicFeedIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Toolbar>
        <Divider orientation="vertical" light />
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(
              classes.drawerFilter,
              !openFilter.treeView &&
                !openFilter.filter &&
                !openFilter.aux &&
                !openFilter.tables &&
                !openFilter.snippet &&
                classes.drawerFilterClose
            )
          }}
          open={
            openFilter.treeView ||
            openFilter.filter ||
            openFilter.aux ||
            openFilter.tables ||
            openFilter.snippet
          }
        >
          {openFilter.reciep && (
            <div style={{ marginTop: 75 }}>
              <div
                style={{
                  justifyContent: 'center',
                  width: '100%',
                  alignContent: 'center',
                  color: '#808080'
                }}
              >
                <div style={{ textAlign: 'center' }}>
                  <p className={classes.filterTitle}>Reciep</p>
                </div>
                <div
                  style={{
                    width: '80%',
                    marginLeft: '40px',
                    marginTop: '10px'
                  }}
                >
                  <select className={classes.selectInput}>
                    <option>PDF</option>
                    <option>Image</option>
                  </select>
                </div>
              </div>
            </div>
          )}

          {openFilter.treeView && (
            <div style={{ marginTop: 75 }}>
              <div
                style={{
                  justifyContent: 'center',
                  width: '100%',
                  alignContent: 'center',
                  color: '#808080'
                }}
              >
                <div style={{ textAlign: 'center' }}>
                  <p className={classes.filterTitle}>
                    {studio.unSave ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <ErrorOutlineIcon
                          style={{ height: 20, color: 'red' }}
                        />
                        <p style={{ color: 'red' }}>{studio.title}</p>
                      </div>
                    ) : (
                      studio.title
                    )}
                  </p>
                </div>
                <ParamsFilter />
              </div>
            </div>
          )}

          {openFilter.filter && (
            <div style={{ marginTop: 75 }}>
              <div
                style={{
                  justifyContent: 'center',
                  width: '100%',

                  alignContent: 'center',

                  color: '#808080'
                }}
              >
                <div>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <p />
                    <p className={classes.filterTitle}>Filter</p>

                    <CloseIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setOpenFilter({
                          params: false,
                          reciep: false,
                          filter: false,
                          snippet: false
                        });
                        dispatch(setOpenMenuStudio(false));
                      }}
                    />
                  </div>
                  <NormalizerFilter />
                </div>
              </div>
            </div>
          )}

          {openFilter.aux && (
            <div style={{ marginTop: 75 }}>
              <div
                style={{
                  justifyContent: 'center',
                  width: '100%',
                  alignContent: 'center',
                  color: '#808080'
                }}
              >
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <p />
                  <p className={classes.filterTitle}>Help</p>

                  <CloseIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setOpenFilter({
                        params: false,
                        reciep: false,
                        filter: false,
                        aux: false,
                        tables: false,
                        snippet: false
                      });
                      dispatch(setOpenMenuStudio(false));
                    }}
                  />
                </div>
                <AuxilioRight />
              </div>
            </div>
          )}

          {openFilter.tables && (
            <div style={{ marginTop: 75 }}>
              <div
                style={{
                  justifyContent: 'center',
                  width: '100%',
                  alignContent: 'center',
                  color: '#808080'
                }}
              >
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <p />
                  <p className={classes.filterTitle}>Tables</p>

                  <CloseIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setOpenFilter({
                        params: false,
                        reciep: false,
                        filter: false,
                        aux: false,
                        tables: false,
                        snippet: false
                      });
                      dispatch(setOpenMenuStudio(false));
                    }}
                  />
                </div>
                <TablesExplores />
              </div>
            </div>
          )}

          {openFilter.snippet && (
            <div style={{ marginTop: 75 }}>
              <div
                style={{
                  justifyContent: 'center',
                  width: '100%',
                  alignContent: 'center',
                  color: '#808080'
                }}
              >
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <p />
                  <p className={classes.filterTitle}>Snippets</p>

                  <CloseIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setOpenFilter({
                        params: false,
                        reciep: false,
                        filter: false,
                        aux: false,
                        tables: false,
                        snippet: false
                      });
                      dispatch(setOpenMenuStudio(false));
                    }}
                  />
                </div>
                <Snippet />
              </div>
            </div>
          )}
        </Drawer>
      </div>
    </Theme>
  );
};

export default NavMenu;
