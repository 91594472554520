/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';

import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EventNoteIcon from '@material-ui/icons/EventNote';
import TranslateIcon from '@material-ui/icons/Translate';
import { BsFillBucketFill } from 'react-icons/bs';
import { FaGlobeAmericas } from 'react-icons/fa';
import { signOut } from '../../../store/modules/auth/actions';
import { useStyles } from './styles';
import ToolTip from '../../Toolpit';

export const MenuList = () => {
  const classes = useStyles();
  const { role } = useSelector(state => state.auth);
  const location = useLocation();

  const [hover, setHover] = useState({ icon: '', show: false });
  const [url, setUrl] = useState();

  useEffect(() => {
    setUrl(location.pathname);
  }, [location.pathname]);

  return (
    <>
      {['master', 'admin', 'dev', 'tester', 'support'].includes(role) && (
        <Link className={classes.link} to="/">
          <ToolTip title="Bucket" placement="right">
            <ListItem
              onMouseEnter={() => setHover({ icon: 'samples', show: true })}
              onMouseLeave={() => setHover({ show: false })}
              key="samples"
              className={url === `/` ? classes.active : classes.desactive}
            >
              <ListItemIcon>
                <BsFillBucketFill
                  style={{
                    color: '#009FE3',
                    fontSize: 25,
                    height: 35,
                    marginLeft: 5
                  }}
                />
              </ListItemIcon>

              {url === `/` ? (
                <ListItemText style={{ color: '#808080' }} primary="Bucket" />
              ) : (
                <ListItemText primary="Bucket" />
              )}
            </ListItem>
          </ToolTip>
        </Link>
      )}
      {['master', 'admin'].includes(role) && (
        <Link className={classes.link} to="/main">
          <ToolTip title="Users" placement="right">
            <ListItem
              onMouseEnter={() => setHover({ icon: 'samples', show: true })}
              onMouseLeave={() => setHover({ show: false })}
              key="users"
              className={url === `/main` ? classes.active : classes.desactive}
            >
              <ListItemIcon>
                <AccountCircleIcon
                  style={{ color: '#009FE3' }}
                  fontSize="large"
                />
              </ListItemIcon>

              {url === `/main` ? (
                <ListItemText style={{ color: '#808080' }} primary="Users" />
              ) : (
                <ListItemText primary="Users" />
              )}
            </ListItem>
          </ToolTip>
        </Link>
      )}
      {['master'].includes(role) && (
        <Link className={classes.link} to="/report">
          <ToolTip title="Studio" placement="right">
            <ListItem
              onMouseEnter={() => setHover({ icon: 'samples', show: true })}
              onMouseLeave={() => setHover({ show: false })}
              key="samples"
              className={url === `/report` ? classes.active : classes.desactive}
            >
              <ListItemIcon>
                <EventNoteIcon style={{ color: '#009FE3' }} fontSize="large" />
              </ListItemIcon>

              {url === `/report` ? (
                <ListItemText style={{ color: '#808080' }} primary="Studio" />
              ) : (
                <ListItemText primary="Studio" />
              )}
            </ListItem>
          </ToolTip>
        </Link>
      )}
      {['master'].includes(role) && (
        <Link className={classes.link} to="/normalizer">
          <ToolTip title="Normalizer" placement="right">
            <ListItem
              onMouseEnter={() => setHover({ icon: 'samples', show: true })}
              onMouseLeave={() => setHover({ show: false })}
              key="samples"
              className={
                url === `/normalizer` ? classes.active : classes.desactive
              }
            >
              <ListItemIcon>
                <TranslateIcon style={{ color: '#009FE3' }} fontSize="large" />
              </ListItemIcon>

              {url === `/normalizer` ? (
                <ListItemText
                  style={{ color: '#808080' }}
                  primary="Normalizer"
                />
              ) : (
                <ListItemText primary="Normalizer" />
              )}
            </ListItem>
          </ToolTip>
        </Link>
      )}

      {['master', 'admin'].includes(role) && (
        <Link className={classes.link} to="/translate">
          <ToolTip title="Translate" placement="right">
            <ListItem
              onMouseEnter={() => setHover({ icon: 'translate', show: true })}
              onMouseLeave={() => setHover({ show: false })}
              key="translate"
              className={
                url === `/translate` ? classes.active : classes.desactive
              }
            >
              <ListItemIcon>
                <FaGlobeAmericas
                  style={{
                    color: '#009FE3',
                    fontSize: 25,
                    height: 35,
                    marginLeft: 5
                  }}
                />
              </ListItemIcon>

              {url === `/translate` ? (
                <ListItemText
                  style={{ color: '#808080' }}
                  primary="Translate"
                />
              ) : (
                <ListItemText primary="Translate" />
              )}
            </ListItem>
          </ToolTip>
        </Link>
      )}
    </>
  );
};

export const Logout = () => {
  const dispatch = useDispatch();

  return (
    <>
      <ToolTip title="logout" placement="right">
        <ListItem
          button
          style={{ width: '100%' }}
          onClick={() => {
            dispatch(signOut());
            window.location.href = '/';
          }}
        >
          <ListItemIcon>
            <PowerSettingsNewIcon style={{ color: '#fff' }} fontSize="large" />
          </ListItemIcon>
          <ListItemText
            style={{ color: '#fff', fontWeight: 'bold' }}
            primary="logout"
          />
        </ListItem>
      </ToolTip>
    </>
  );
};
