import React from 'react';
import { Switch } from 'react-router-dom';
import { Translate, SignIn, Main, Report, Normalizer, Bucket } from '../pages';
import NavMenu from '../componentes/NavMenu/index';
import Route from './Route';

export default function Routes() {
  return (
    <Switch>
      <Route path="/signin" component={SignIn} />
      <NavMenu>
        <Switch>
          <Route isPrivate exact path="/" component={Bucket} />
          <Route isPrivate path="/main" component={Main} />
          <Route isPrivate path="/report" component={Report} />
          <Route isPrivate path="/normalizer" component={Normalizer} />
          <Route isPrivate path="/translate" component={Translate} />
        </Switch>
      </NavMenu>
    </Switch>
  );
}
