import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Backoffice from 'assets/Backoffice.png';
import { useClearCache } from 'react-clear-cache';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { signInRequest } from '../../store/modules/auth/actions';
import { useStyles } from './styles';

export default function Sign() {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const { emptyCacheStorage } = useClearCache();

  const emailRef = useRef();
  const passwordRef = useRef();

  const { signed } = useSelector(state => state.auth);

  const classes = useStyles(signed);
  const dispatch = useDispatch();

  function handleSignIn(e) {
    e.preventDefault();
    if (!login) {
      emailRef.current.focus();
      return toast.error('Fill in the email');
    }

    if (!password) {
      passwordRef.current.focus();
      return toast.error('Fill in the password');
    }

    dispatch(signInRequest(login, password));
  }

  const updateVersion = () => {
    const response = window.confirm('Do you want to update the version?');

    if (response) {
      localStorage.clear();
      sessionStorage.clear();
      Cookies.remove();
      emptyCacheStorage();
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />

      <Grid item xs={8} sm={8} md={4} component={Paper} elevation={6} square>
        <span onClick={() => updateVersion()} className={classes.version}>
          1.5.11
        </span>

        <div className={classes.paper}>
          <img
            alt="logo"
            className={classes.logo}
            style={{ height: 140, width: 330 }}
            src={Backoffice}
          />

          {window.location.pathname === '/' && (
            <form onSubmit={handleSignIn}>
              <small>Email</small>
              <input
                className={classes.input}
                type="email"
                value={login}
                id="email"
                ref={emailRef}
                onChange={e => setLogin(e.target.value)}
              />
              <small>Password</small>

              <input
                className={classes.input}
                type="password"
                value={password}
                ref={passwordRef}
                autoComplete="new-password"
                onChange={e => setPassword(e.target.value)}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={classes.submit}
              >
                Sign in
              </Button>
            </form>
          )}

          <img
            alt="logo"
            className={classes.logo}
            style={{
              height: 60,
              width: 200,
              marginLeft: '40%',
              marginTop: '15%'
            }}
            src={require('assets/DSM_MasterLogo.png')}
          />
        </div>
      </Grid>
      <Grid item xs={false} sm={10} md={8} className={classes.image} />
    </Grid>
  );
}
